import React from "react"
import styled from "@emotion/styled"
import { Flex, Box, Text } from "rebass"
import Img from "gatsby-image"
import { BLOCKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import Layout from "../components/layout"

const BlogContainer = styled(Flex)`
  margin: 100px 20px;
  flex-direction: column;

  ${(props) => props.theme.bp.tablet} {
    margin-top: 250px;
  }

  ${(props) => props.theme.bp.mobile} {
    margin-top: 250px;
  }

  ${(props) => props.theme.bp.desktop} {
    flex-direction: row;
    margin: 200px auto;
    width: 70%;
  }
`

const ImageContainer = styled(Flex)`
  flex: 1;

  ${(props) => props.theme.bp.mobile} {
    margin-bottom: 20px;
  }

  ${(props) => props.theme.bp.tablet} {
    margin-bottom: 20px;
  }

  ${(props) => props.theme.bp.desktop} {
    padding: 20px;
  }
`

const Image = styled(Img)`
  width: 100%;
`

const ContentContainer = styled(Flex)`
  flex: 1;
  font-family: Georgia;
  flex-direction: column;

  a {
    color: #454545;
  }
  ${(props) => props.theme.bp.mobile} {
    font-size: 12px;
  }

  ${(props) => props.theme.bp.desktop} {
    padding: 20px;
    font-size: 14px;
  }
`

const Title = styled(Text)`
  text-transform: uppercase;
  font-weight: 600;
`

const renderOptions = {
  renderNode: {
    [BLOCKS.HEADING_6]: (node, children) => <SmallText>{children}</SmallText>,
  },
}

const Blog = ({ pageContext, data }) => {
  const { contentfulTile: content } = data

  return (
    <Layout>
      <BlogContainer>
        <ImageContainer>
          <Image fluid={content.backgroundImage.fluid} />
        </ImageContainer>
        <ContentContainer>
          <Title mb={3}>{content.title}</Title>
          {content.body &&
            content.body.json &&
            documentToReactComponents(content.body.json, renderOptions)}
        </ContentContainer>
      </BlogContainer>
    </Layout>
  )
}

export default Blog

export const query = graphql`
  query($linkUrl: String) {
    contentfulTile(linkUrl: { eq: $linkUrl }) {
      ...Tile
    }
  }
`
